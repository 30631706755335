<template>
  <section class="section-block">
    <SectionTitle :title="displayText.sectionTitle" hideBtn />

    <BaseElForm v-loading="loading.notify" label-position="left" label-width="250px" style="margin-top: 22px">
      <BaseElFormItem :label="displayText.formItemLabel.bindingLineAccount">
        <BaseElButton v-if="showControl.btn.bindLine" :loading="loading.bindLine" type="primary" @click="onBindLineAccount">{{ displayText.btn.goBinding }}</BaseElButton>
        <BaseElButton v-if="showControl.btn.unbindLine" :loading="loading.unbindLine" plain @click="onUnBindLineAccount">{{ displayText.btn.cancelBinding }}</BaseElButton>
      </BaseElFormItem>

      <BaseElFormItem :label="displayText.formItemLabel.enabled">
        <template #label>
          <FormItemTooltipLabel :label="displayText.formItemLabel.enabled" :tooltip-width="260">
            {{ displayText.tooltip.enabled }}
          </FormItemTooltipLabel>
        </template>
        <BaseElSwitch
          v-model="formData.enabled"
          :active-text="displayText.common.open"
          :inactive-text="displayText.common.close"
          @change="onToggle($event, 'enabled')"
        />
      </BaseElFormItem>

      <template v-if="showControl.notifyEvents">
        <BaseElFormItem
          v-for="notify in notifyConfigs"
          :key="notify.key"
          :label="notify.label"
        >
          <template v-if="notify.tooltip" #label>
            <FormItemTooltipLabel :label="notify.label" :tooltip-width="260">
              {{ notify.tooltip }}
            </FormItemTooltipLabel>
          </template>
          <BaseElSwitch
            v-model="formData.allowNotifyEvents[notify.key]"
            :active-text="displayText.common.open"
            :inactive-text="displayText.common.close"
            @change="onToggle"
          />
        </BaseElFormItem>
      </template>
    </BaseElForm>
  </section>
</template>

<script>
import { UserNotificationGetLineLoginAuthorizationUrl, UserNotificationUpdateLineBinding, UserNotificationUnbindLine } from '@/api/user'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import notifyMessage from '@/config/notifyMessage'
import { i18n } from '@/plugins/i18n/i18n'
import { useBaseForm } from '@/use/useForm'
import { useOrg } from '@/use/useOrg'
import { includes, get } from 'lodash'

import { computed, defineComponent, inject, reactive, set, watch } from 'vue'

export default defineComponent({
  name: 'SystemLineNotifySettings',
  components: {
    FormItemTooltipLabel,
  },
  setup (props, { emit }) {
    const bindingConfigData = inject('bindingConfigData')
    const { formData, initFormData } = useBaseForm()
    const loading = reactive({
      bindLine: false,
      unbindLine: false,
      notify: false,
    })
    const { orgId } = useOrg()
    initFormData({
      enabled: false,
      allowNotifyEvents: {},
    })
    const notifyConfigs = computed(() => [
      {
        key: 'appointmentReservation.created',
        label: i18n.t('accountInfo.lineNotifySettings.appointmentCreated.label'),
      },
      {
        key: 'appointmentReservation.cancel',
        label: i18n.t('accountInfo.lineNotifySettings.appointmentCanceled.label'),
      },
      {
        key: 'ecommerceOrder.atmReported',
        label: i18n.t('accountInfo.lineNotifySettings.ecommerceOrderAtmReported.label'),
      },
      {
        key: 'ecommerceOrder.open',
        label: i18n.t('accountInfo.lineNotifySettings.ecommerceOrderOpen.label'),
      },
      {
        key: 'ecommerceOrder.cancel',
        label: i18n.t('accountInfo.lineNotifySettings.ecommerceOrderCancel.label'),
      },
    ])

    for (const item of notifyConfigs.value) {
      set(formData.allowNotifyEvents, item.key, false)
    }

    const displayText = computed(() => {
      const text = {
        sectionTitle: i18n.t('accountInfo.block.lineNotifySettings.title'),
        formItemLabel: {
          enabled: i18n.t('accountInfo.lineNotifySettings.enabledLineNotify.label'),
          bindingLineAccount: i18n.t('accountInfo.lineNotifySettings.bindLineAccount.label'),
        },
        tooltip: {
          enabled: i18n.t('accountInfo.lineNotifySettings.enabledLineNotify.tooltip'),
        },
        btn: {
          goBinding: i18n.t('accountInfo.block.lineNotifySettings.btn.goBinding'),
          cancelBinding: i18n.t('accountInfo.block.lineNotifySettings.btn.cancelBinding'),
        },
        notifyMsg: {
          plsBindLineAccount: i18n.t('accountInfo.lineNotifySettings.notify.plsBindLineAccount.content'),
        },
        common: {
          open: i18n.t('common.open.text'),
          close: i18n.t('common.close.text'),
        },
      }
      return text
    })

    const showControl = computed(() => {
      const control = {
        notifyEvents: false,
        btn: {
          bindLine: false,
          unbindLine: false,
        },
      }

      if (formData.enabled) {
        control.notifyEvents = true
      }

      const isBinding = get(bindingConfigData.value, 'lineBinding.isBinding', false)
      if (isBinding) {
        control.btn.unbindLine = true
      } else {
        control.btn.bindLine = true
      }

      return control
    })

    const submitPayload = computed(() => {
      const payload = {
        orgId: orgId.value,
        enabled: formData.enabled,
        allowNotifyEvents: [],
      }

      for (const key in formData.allowNotifyEvents) {
        if (formData.allowNotifyEvents[key]) {
          payload.allowNotifyEvents.push(key)
        }
      }

      return payload
    })

    const onBindLineAccount = async () => {
      const [res, errMsg, rawErr] = await UserNotificationGetLineLoginAuthorizationUrl({
        orgId: orgId.value,
        clientRedirectUri: window.location.href,
      })
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      window.location = res.url
    }

    const onUnBindLineAccount = async () => {
      loading.unbindLine = true
      const [res, errMsg, rawErr] = await UserNotificationUnbindLine({
        orgId: orgId.value,
      })
      loading.unbindLine = false
      if (errMsg) {
        window.$message.error(errMsg)
      } else {
        formData.enabled = false
        const [, errMsg, rawErr] = await UserNotificationUpdateLineBinding(submitPayload.value)
        if (errMsg) {
          window.$message.error(errMsg)
          return
        }
        window.$message.success(notifyMessage.updateSuccess)
        emit('refresh')
      }
    }

    const onToggle = async (toggle, key) => {
      const isBinding = get(bindingConfigData.value, 'lineBinding.isBinding', false)
      if (toggle) {
        if (!isBinding) {
          formData[key] = !toggle
          window.$message.error(displayText.value.notifyMsg.plsBindLineAccount)
          return
        }
      }

      loading.notify = true
      const [, errMsg, rawErr] = await UserNotificationUpdateLineBinding(submitPayload.value)
      loading.notify = false
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
    }

    const syncFormData = () => {
      formData.enabled = get(bindingConfigData.value, 'lineBinding.enabled', false)

      const allowNotifyEvents = get(bindingConfigData.value, 'lineBinding.allowNotifyEvents', [])
      for (const notify of notifyConfigs.value) {
        formData.allowNotifyEvents[notify.key] = includes(allowNotifyEvents, notify.key)
      }
    }

    watch(bindingConfigData, () => {
      syncFormData()
    })

    return {
      loading,
      onToggle,
      showControl,
      formData,
      notifyConfigs,
      displayText,
      onBindLineAccount,
      onUnBindLineAccount,
      bindingConfigData,
    }
  },
})

</script>

<style lang="postcss" scoped>
</style>
