<template>
  <section v-loading="!loaded" class="section-block">
    <SectionTitle :title="displayText.sectionTitle" hideBtn />
    <BaseElForm
      ref="formRef"
      label-position="left"
      label-width="150px"
      style="margin-top: 22px"
      :model="formData"
      :rules="formRules"
    >
      <div class="form-container">
        <BaseElFormItem :label="displayText.label.userRole">
          <span>{{ displayData.userRole }}</span>
        </BaseElFormItem>
        <BaseElFormItem :label="displayText.label.userName">
          <span>{{ displayData.userName }}</span>
        </BaseElFormItem>
        <BaseElFormItem :label="displayText.label.accountEmail">
          <span>{{ displayData.userAccountEmail }}</span>
        </BaseElFormItem>
        <BaseElFormItem :label="displayText.label.enabledEmailNotify">
          <BaseElSwitch
            v-model="formData.enabledEmailNotify"
            :active-text="displayText.common.open"
            :inactive-text="displayText.common.close"
            @change="onToggleNotifyEmail"
          />
        </BaseElFormItem>
        <BaseElFormItem prop="notifyEmail" :label="displayText.label.notifyEmail">
          <div class="flex flex-col">
            <BaseElInput
              v-model="formData.notifyEmail"
              :disabled="disabledControl.formItem.notifyEmail"
            />
            <BaseElCheckbox
              v-model="formData.sameAsAccountEmail"
              :label="displayText.checkbox.sameAsAccountEmail"
              @change="onSameAsAccountEmailChange"
            />
            <BaseElButton
              style="width: fit-content; margin-top: 32px"
              type="primary"
              :loading="loading"
              @click="onUpdateNotifyEmail"
            >
              {{ displayText.btn.confirmUpdate }}
            </BaseElButton>
          </div>
        </BaseElFormItem>
      </div>
    </BaseElForm>
  </section>
</template>

<script>
import { FindCustomRole, UpdateUserNotificationBindEmail } from '@/api/user'
import notifyMessage from '@/config/notifyMessage'
import store from '@/store'
import { useBaseForm } from '@/use/useForm'
import { useOrg } from '@/use/useOrg'
import { get } from 'lodash'
import { computed, defineComponent, inject, onBeforeMount, ref, watch } from 'vue'
import adminRole from '@/config/admin'
import { apiFormatAdaptor } from '@/utils/api'
import { emailRules, noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'BasicInfo',
  setup (props, { emit }) {
    const bindingConfigData = inject('bindingConfigData')
    const { orgId } = useOrg()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    initFormData({
      enabledEmailNotify: false,
      notifyEmail: '',
      sameAsAccountEmail: false,
    })
    const loaded = ref(false)
    const adminRoleList = ['orgAdmin', 'shopManager', 'shopProvider', 'shopMod', 'ohbotAdmin']
    const customRoleOptions = ref([])

    const displayText = computed(() => {
      const text = {
        sectionTitle: i18n.t('accountInfo.block.basicInfo.title'),
        label: {
          userRole: i18n.t('accountInfo.basicInfo.role.label'),
          userName: i18n.t('accountInfo.basicInfo.name.label'),
          accountEmail: i18n.t('accountInfo.basicInfo.accountEmail.label'),
          enabledEmailNotify: i18n.t('accountInfo.basicInfo.enabledEmailNotify.label'),
          notifyEmail: i18n.t('accountInfo.basicInfo.notifyEmail.label'),
        },
        checkbox: {
          sameAsAccountEmail: i18n.t('accountInfo.basicInfo.checkbox.sameAsAccountEmail'),
        },
        btn: {
          confirmUpdate: i18n.t('common.button.confirmUpdate.text'),
        },
        common: {
          open: i18n.t('common.open.text'),
          close: i18n.t('common.close.text'),
        },
      }
      return text
    })

    const formRules = computed(() => {
      const rules = {
        notifyEmail: [],
      }

      if (formData.enabledEmailNotify) {
        rules.notifyEmail.push(noEmptyRules())
        rules.notifyEmail.push(emailRules())
      }

      return rules
    })

    const availableRoleList = computed(() => {
      const availableRoleList = []
      adminRoleList.forEach(item => {
        availableRoleList.push(adminRole[item])
      })
      customRoleOptions.value.map(item => {
        availableRoleList.push({ name: item.name, value: item.role })
      })

      return availableRoleList
    })

    const displayRole = (role) => {
      const targetRole = availableRoleList.value.find(item => item.value === role)
      return targetRole ? targetRole.name : ''
    }

    const displayData = computed(() => {
      const user = get(store, 'getters.user')
      const data = {
        userRole: displayRole(get(user, 'role', '')),
        userName: get(user, 'name', ''),
        userAccountEmail: get(user, 'AuthEmail.email', ''),
        contactEmail: get(user, 'AuthEmail.email', ''),
      }
      return data
    })

    const disabledControl = computed(() => {
      const controls = {
        formItem: {
          notifyEmail: false,
        },
      }

      if (formData.sameAsAccountEmail) {
        controls.formItem.notifyEmail = true
      }

      return controls
    })

    const onSameAsAccountEmailChange = () => {
      if (formData.sameAsAccountEmail) {
        formData.notifyEmail = displayData.value.userAccountEmail
      }
    }

    const onUpdateNotifyEmail = async () => {
      const pass = await checkForm(formRef.value)
      if (!pass) return
      loading.value = true
      const [, errMsg, rawErr] = await UpdateUserNotificationBindEmail({
        orgId: orgId.value,
        enabled: formData.enabledEmailNotify,
        email: formData.notifyEmail,
      })
      loading.value = false
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
    }

    const getUserCustomRole = async () => {
      const [res, errMsg, rawErr] = await apiFormatAdaptor(FindCustomRole)()
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      customRoleOptions.value = res
    }

    const onToggleNotifyEmail = () => {
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 0)
    }

    watch(bindingConfigData, () => {
      const bindEmail = get(bindingConfigData.value, 'emailBinding.email')
      formData.notifyEmail = bindEmail
      if (bindEmail === displayData.value.userAccountEmail) {
        formData.sameAsAccountEmail = true
      }
      formData.enabledEmailNotify = get(bindingConfigData.value, 'emailBinding.enabled')
      loaded.value = true
    })

    onBeforeMount(() => {
      getUserCustomRole()
    })

    return {
      loading,
      loaded,
      formRef,
      formData,
      displayData,
      disabledControl,
      onSameAsAccountEmailChange,
      onUpdateNotifyEmail,
      formRules,
      onToggleNotifyEmail,
      displayText,
    }
  },
})

</script>

<style lang="postcss" scoped>
</style>
